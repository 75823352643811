import { Button, Grid, TextField } from '@mui/material';
import { Template } from 'api/reports/useGetTemplates';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import OverwriteTemplate from './OverwriteTemplate';
import { StandardTemplateBody, PlantTemplateBody } from 'features/new-reports/models/reportsModels';

type Props = {
  open : boolean;
  onClose : () => void;
  setNewTemplate : (newTemplate : Template) => void;
  takenNames : string[];
  fillTemplateDetails : (template : StandardTemplateBody | PlantTemplateBody) => void;
};
const NewTemplate : FC<Props> = ({
  open,
  onClose,
  setNewTemplate,
  takenNames,
  fillTemplateDetails,
}) => {
  const [templateName, setTemplateName] = useState('');
  const [showError, setShowError] = useState(false);

  const isError = () => {
    return (
      !!templateName && takenNames.some((name) => name.toLowerCase() === templateName.toLowerCase())
    );
  };
  const handleClick = () => {
    if (isError()) {
      setShowError(true);
    } else {
      setNewTemplate({
        id: 0,
        name: templateName,
        createdOn: new Date().toLocaleTimeString(),
      });
      onClose();
    }
  };
  const handleTemplateName = (e : { target : { value : string } }) => {
    showError && setShowError(false);
    setTemplateName(e.target.value.trimStart());
  };

  return (
    <DodgeModal open={open} onClose={onClose} title='Create template' width='40rem'>
      <Grid container flexDirection='column' mt={3}>
        <Grid item container xs={12} gap={3} mb={3} alignItems='center'>
          <Grid item xs={8}>
            <TextField
              id='templateName'
              label='Template Name'
              variant='outlined'
              required
              fullWidth
              value={templateName}
              onChange={handleTemplateName}
              size='small'
              error={showError}
              helperText={showError ? 'This name has already been used.' : ''}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              onClick={handleClick}
              disabled={!templateName}
              sx={{ color: 'black', fontWeight: '600' }}
            >
              save
            </Button>
          </Grid>
        </Grid>
        <OverwriteTemplate />
      </Grid>
    </DodgeModal>
  );
};

export default NewTemplate;
