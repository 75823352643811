import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { LoggedInProfile } from '../LoggedInProfile';
import style from './Bar.module.scss';
import { setPlant } from 'store/accountSlice';
import { useDispatch } from 'react-redux';
import { useGetUserRoles } from 'api/plants-management/users/useGetUserRoles';

const Bar = () => {
  const { data = [] } = useGetUserRoles();
  const dispatch = useDispatch();

  const userRoles = data.filter((x) => x.invitationStatus.toLowerCase() === 'accepted');

  React.useEffect(() => {
    if (userRoles.length) {
      dispatch(setPlant(userRoles[0].plantId));
    }
  }, [data]);

  return (
    <Grid container direction='row' justifyContent='space-between'>
      <Grid item className={style.select}></Grid>
      <Grid item>
        <Box>
          <Typography variant='h4' style={{ color: 'lightgray' }}>
            {process.env.REACT_APP_TITLE}
          </Typography>
        </Box>
      </Grid>
      <Grid item sx={{ display: 'flex' }}>
        <LoggedInProfile />
      </Grid>
    </Grid>
  );
};
export default React.memo(Bar);
