import React from 'react';
import { Box } from '@mui/material';
import { CopyrightBar } from '../../CopyrightBar';
import style from '../ErrorCodeRedirectionLayout/ErrorCodeRedirectionLayout.module.scss';

const LayoutContext = React.createContext(null);

type PropsTypes = {
  children : React.ReactNode;
};

const ExpiredLayout = ({ children } : PropsTypes) => {
  return (
    <LayoutContext.Provider value={null}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={style.page}
        gap='1.5rem'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          paddingLeft={4}
          paddingRight={4}
          paddingTop={4}
        >
          <img src={'https://i.ibb.co/wzGWZVd/logo.png'} className={style.img} alt='DRG logo' />
        </Box>
        <Box display='flex' justifyContent='center'>
          {children}
        </Box>
        <Box>
          <CopyrightBar></CopyrightBar>
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};

export default React.memo<PropsTypes>(ExpiredLayout);
