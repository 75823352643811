import { UseQueryResult } from 'react-query';
import { useAuthQuery } from 'api/queries';
import { toast } from 'react-toastify';

export interface Template {
  id : number;
  name : string;
  createdOn : string;
}

export const useGetChannelPartnersTemplates = (
  channelId : number | string,
  plantId : number | null,
  reportType : string,
) => {
  return useAuthQuery(
    `channel-partners/${channelId}/report-templates?plantId=${plantId}&reportType=${reportType}`,
    0,
    {
      onError: () => {
        toast.error('Failed to get templates.');
      },
      enabled: plantId !== null,
    },
  ) as UseQueryResult<Template[]>;
};
