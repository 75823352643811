/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */

import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_B2C_CLIENT_ID}`,
    authority: `https://${process.env.REACT_APP_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_POLICY_ID}`,
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT}.b2clogin.com`],
    redirectUri: `${process.env.REACT_APP_DOMAIN}/dashboard`,
    postLogoutRedirectUri: `${process.env.REACT_APP_DOMAIN}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};
