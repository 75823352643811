import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface PlantsAdminSummaryState {
  summary : {
    numberOfSensors : number;
    numberOfGateways : number;
    numberOfPlants : number;
    completeDataSensors : number;
    partialDataSensors : number;
    noDataSensors : number;
    noCommissionsSensors : number;
    onlineGateways : number;
    offlineGateways : number;
    partialOnlineGateways : number;
    plantsSelected : number;
    sensorsInGWRange : number;
  };
  sortBy : string;
  currentTotalRecords : number;
}

const initialState : PlantsAdminSummaryState = {
  summary: {
    numberOfSensors: 0,
    numberOfGateways: 0,
    numberOfPlants: 0,
    completeDataSensors: 0,
    partialDataSensors: 0,
    noDataSensors: 0,
    noCommissionsSensors: 0,
    onlineGateways: 0,
    offlineGateways: 0,
    partialOnlineGateways: 0,
    plantsSelected: 0,
    sensorsInGWRange: 0,
  },
  currentTotalRecords: 0,
  sortBy: '',
};

export const plantsAdminSummarySlice = createSlice({
  name: 'plantsAdminSummary',
  initialState,
  reducers: {
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setCurrentTotalRecords: (state, action) => {
      state.currentTotalRecords = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setSummary, setCurrentTotalRecords, setSortBy } = plantsAdminSummarySlice.actions;

export const selectSummary = (state : RootState) => state.plantsAdminSummary.summary;
export const selectCurrentTotalRecords = (state : RootState) =>
  state.plantsAdminSummary.currentTotalRecords;
export const selectSortBy = (state : RootState) => state.plantsAdminSummary.sortBy;

export default plantsAdminSummarySlice.reducer;
