import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  Sensor,
  Asset,
} from 'features/plants-management/components/AssetsAndAssetGroups/AssetGroupAdmin.model';

export interface Pulley2xBearingSlice {
  activeLeftAsset : Asset | null;
  activeRightAsset : Asset | null;
  activeLeftSensor : Sensor | null;
  activeRightSensor : Sensor | null;
  numberOfHarmonics : number;
  marginRange : number;
  nominalSpeed : number;
  selectedMeasurements : string[];
  faultFrequencies : string[];
  windowingFunction : string;
  viewType : string;
  colorScale : string;
  axis : string;
  runningSpeedFFT : number | null;
  activeRMS : string;
  activeUnit : string;
  filterBeans : number;
  faultDetectionSettings : string;
  aggregationTime : string;
  downtimeSource : string;
  downtimeThreshold : number;
  kpiType : string;
  setInitialAssetsAndSensors : boolean;
  bearingReadyToAnalytics : { left : string; right : string };
}

const initialState : Pulley2xBearingSlice = {
  numberOfHarmonics: 1,
  marginRange: 60,
  nominalSpeed: 6000,
  selectedMeasurements: [],
  faultFrequencies: ['runningSpeed'],
  windowingFunction: 'None',
  viewType: '3d',
  colorScale: 'Greys',
  axis: 'Z',
  runningSpeedFFT: null,
  activeRMS: 'Velocity-Z',
  activeUnit: 'Hz',
  filterBeans: 10,
  activeLeftAsset: null,
  activeRightAsset: null,
  activeLeftSensor: null,
  activeRightSensor: null,
  faultDetectionSettings: 'runningSpeed-1',
  aggregationTime: 'month',
  downtimeSource: 'velocity-z',
  downtimeThreshold: 0.3,
  kpiType: 'velocity',
  setInitialAssetsAndSensors: true,
  bearingReadyToAnalytics: { left: 'ready', right: 'ready' },
};

export const pulley2xBearingSlice = createSlice({
  name: 'pulley2xBearing',
  initialState,
  reducers: {
    setNumberOfHarmonics: (state, action) => {
      state.numberOfHarmonics = action.payload;
    },
    setMarginRange: (state, action) => {
      state.marginRange = action.payload;
    },
    setNominalSpeed: (state, action) => {
      state.nominalSpeed = action.payload;
    },
    setSelectedMeasurements: (state, action) => {
      state.selectedMeasurements = action.payload;
    },
    setFaultFrequencies: (state, action) => {
      state.faultFrequencies = action.payload;
    },
    setWindowingFunction: (state, action) => {
      state.windowingFunction = action.payload;
    },
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    setColorScale: (state, action) => {
      state.colorScale = action.payload;
    },
    setAxis: (state, action) => {
      state.axis = action.payload;
    },
    setRunningSpeedFFT: (state, action) => {
      state.runningSpeedFFT = action.payload;
    },
    setActiveRMS: (state, action) => {
      state.activeRMS = action.payload;
    },
    setActiveUnit: (state, action) => {
      state.activeUnit = action.payload;
    },
    setFilterBeans: (state, action) => {
      state.filterBeans = action.payload;
    },
    setActiveLeftAsset: (state, action) => {
      state.activeLeftAsset = action.payload;
    },
    setActiveRightAsset: (state, action) => {
      state.activeRightAsset = action.payload;
    },
    setActiveLeftSensor: (state, action) => {
      state.activeLeftSensor = action.payload;
    },
    setActiveRightSensor: (state, action) => {
      state.activeRightSensor = action.payload;
    },
    setFaultDetectionSettings: (state, action) => {
      state.faultDetectionSettings = action.payload;
    },
    setAggregationTime: (state, action) => {
      state.aggregationTime = action.payload;
    },
    setDowntimeSource: (state, action) => {
      state.downtimeSource = action.payload;
    },
    setDowntimeThreshold: (state, action) => {
      state.downtimeThreshold = action.payload;
    },
    setKpiType: (state, action) => {
      state.kpiType = action.payload;
    },
    setSetInitialAssetsAndSensors: (state, action) => {
      state.setInitialAssetsAndSensors = action.payload;
    },
    setBearingReadyToAnalytics: (state, action) => {
      state.bearingReadyToAnalytics = action.payload;
    },
  },
});

export const {
  setSelectedMeasurements,
  setFaultFrequencies,
  setWindowingFunction,
  setViewType,
  setColorScale,
  setAxis,
  setRunningSpeedFFT,
  setMarginRange,
  setNominalSpeed,
  setNumberOfHarmonics,
  setActiveRMS,
  setActiveUnit,
  setFilterBeans,
  setActiveLeftAsset,
  setActiveLeftSensor,
  setActiveRightAsset,
  setActiveRightSensor,
  setFaultDetectionSettings,
  setAggregationTime,
  setDowntimeSource,
  setDowntimeThreshold,
  setKpiType,
  setSetInitialAssetsAndSensors,
  setBearingReadyToAnalytics,
} = pulley2xBearingSlice.actions;

export const selectNumberOfHarmonics = (state : RootState) =>
  state.pulley2xBearing.numberOfHarmonics;
export const selectMarginRange = (state : RootState) => state.pulley2xBearing.marginRange;
export const selectNominalSpeed = (state : RootState) => state.pulley2xBearing.nominalSpeed;
export const selectedMeasurements = (state : RootState) =>
  state.pulley2xBearing.selectedMeasurements;
export const selectFaultFrequencies = (state : RootState) => state.pulley2xBearing.faultFrequencies;
export const selectWindowingFunction = (state : RootState) =>
  state.pulley2xBearing.windowingFunction;
export const selectViewType = (state : RootState) => state.pulley2xBearing.viewType;
export const selectColorScale = (state : RootState) => state.pulley2xBearing.colorScale;
export const selectAxis = (state : RootState) => state.pulley2xBearing.axis;
export const selectRunningSpeedFFT = (state : RootState) => state.pulley2xBearing.runningSpeedFFT;
export const selectActiveRMS = (state : RootState) => state.pulley2xBearing.activeRMS;
export const selectActiveUnit = (state : RootState) => state.pulley2xBearing.activeUnit;
export const selectFilterBeans = (state : RootState) => state.pulley2xBearing.filterBeans;
export const selectActiveLeftAsset = (state : RootState) => state.pulley2xBearing.activeLeftAsset;
export const selectActiveLeftSensor = (state : RootState) => state.pulley2xBearing.activeLeftSensor;
export const selectActiveRightAsset = (state : RootState) => state.pulley2xBearing.activeRightAsset;
export const selectActiveRightSensor = (state : RootState) =>
  state.pulley2xBearing.activeRightSensor;
export const selectFaultDetectionSettings = (state : RootState) =>
  state.pulley2xBearing.faultDetectionSettings;
export const selectAggregationTime = (state : RootState) => state.pulley2xBearing.aggregationTime;
export const selectDowntimeSource = (state : RootState) => state.pulley2xBearing.downtimeSource;
export const selectDowntimeThreshold = (state : RootState) =>
  state.pulley2xBearing.downtimeThreshold;
export const selectKpiType = (state : RootState) => state.pulley2xBearing.kpiType;
export const selectSetInitialAssetsAndSensors = (state : RootState) =>
  state.pulley2xBearing.setInitialAssetsAndSensors;
export const selectBearingReadyToAnalytics = (state : RootState) =>
  state.pulley2xBearing.bearingReadyToAnalytics;

export default pulley2xBearingSlice.reducer;
