import { UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { useAuthQuery } from 'api/queries';
import {
  BatteryTemplateBody,
  MaintenanceTemplateBody,
  PlantTemplateBody,
  StandardTemplateBody,
} from 'features/new-reports/models/reportsModels';

export const useGetTemplate = (templateId : number) => {
  return useAuthQuery(`report-templates/${templateId}`, 0, {
    onError: () => {
      toast.error('Failed to get template.');
    },
    enabled: templateId !== 0,
    select(
      data :
        | StandardTemplateBody
        | PlantTemplateBody
        | MaintenanceTemplateBody
        | BatteryTemplateBody,
    ) {
      if (data.type === 'Standard' || data.type === 'Maintenance' || data.type === 'Battery')
        return data;
      if (data.type === 'Plant')
        return {
          ...data,
          settings: {
            ...data.settings,
            imageURL: data.settings.imageURL
              ? data.settings.imageURL.split('/')[1]
              : data.settings.imageURL,
          },
        } as PlantTemplateBody;
    },
  }) as UseQueryResult<StandardTemplateBody | PlantTemplateBody>;
};
