import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { View } from '../models/contract.model';
import { ReportType } from '../models/report.models';
import { ChannelPartnerShort } from 'api/channel-partners/getChannelPartnersShort';
import { Distributor } from '../models/user.model';
import { ChannelDetails } from 'api/plants-management/users/useGetUserRoles';

export interface ChannelState {
  view : View;
  selectedChannel : ChannelPartnerShort | null;
  reportType : ReportType;
  selectedUsers : (number | string)[];
  recordsLength : number;
  selectedRole : Distributor | string;
  channelDetails : ChannelDetails | null | undefined;
  changesDetected : boolean;
}

const initialState : ChannelState = {
  view: 'contracts',
  selectedChannel: null,
  reportType: 'history',
  selectedUsers: [],
  recordsLength: 0,
  selectedRole: 'all',
  channelDetails: null,
  changesDetected: false,
};

export const channelsSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setRecordsLength: (state, action) => {
      state.recordsLength = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setChannelDetails: (state, action) => {
      state.channelDetails = action.payload;
    },
    setChangesDetected: (state, action) => {
      state.changesDetected = action.payload;
    },
  },
});

export const {
  setView,
  setSelectedChannel,
  setReportType,
  setSelectedUsers,
  setRecordsLength,
  setSelectedRole,
  setChannelDetails,
  setChangesDetected,
} = channelsSlice.actions;

export const selectView = (state : RootState) => state.channel.view;
export const selectSelectedChannel = (state : RootState) => state.channel.selectedChannel;
export const selectReportType = (state : RootState) => state.channel.reportType;
export const selectSelectedUsers = (state : RootState) => state.channel.selectedUsers;
export const selectRecordsLength = (state : RootState) => state.channel.recordsLength;
export const selectSelectedRole = (state : RootState) => state.channel.selectedRole;
export const selectChannelDetails = (state : RootState) => state.channel.channelDetails;
export const selectChangesDetected = (state : RootState) => state.channel.changesDetected;

export default channelsSlice.reducer;
