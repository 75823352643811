import axios from 'axios';
import { msalInstance } from 'index';

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: 'json',
});

const getToken = async () => {
  const accounts = await msalInstance.getAllAccounts();
  msalInstance.setActiveAccount(accounts[0]);
  const authResult = await msalInstance.acquireTokenSilent({
    scopes: [
      `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_API_SCOPE}`,
    ],
    // refreshTokenExpirationOffsetSeconds: 60,
  });

  return authResult;
};

axiosApi.interceptors.request.use(async (config) => {
  const authResult = await getToken();

  if (authResult.accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authResult.accessToken}`,
    };
  } else {
    msalInstance.loginRedirect();
  }
  return config;
});

export default axiosApi;
