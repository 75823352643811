import { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import LoginAvatar from './LoginAvatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface LoginProps {
  open : boolean;
  handleClick : (event : React.MouseEvent<HTMLElement>) => void;
  firstName : string;
  lastName : string;
  email : string;
}

const Login : FC<LoginProps> = ({ open, handleClick, firstName, lastName, email }) => {
  return (
    <Box
      data-testid='login_navbar_menu'
      display='inline-flex'
      alignItems='center'
      id='user'
      aria-controls={open ? 'menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <LoginAvatar size='2rem' />
      <Box>
        <Typography
          fontWeight='600'
          letterSpacing='0.06rem'
          color='white'
        >{`${firstName} ${lastName}`}</Typography>
        <Typography fontSize='0.9rem' color='white'>
          {email}
        </Typography>
      </Box>
      <ArrowDropDownIcon sx={{ color: 'white', fontSize: '2rem', ml: '1rem' }} />
    </Box>
  );
};

export default memo(Login);
