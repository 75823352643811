import React from 'react';
import ReactDOM from 'react-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './features/login/pages/msalConfig';

import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Routes from './routes/Routes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer, Slide } from 'react-toastify';
import theme from './theme/theme';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './fonts/archivo.ttf';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { MsalProvider } from '@azure/msal-react';

export const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
            <ToastContainer transition={Slide} limit={1} theme='colored' autoClose={2500} />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
