import { UseQueryResult } from 'react-query';
import { useAuthQuery } from 'api/queries';
import { toast } from 'react-toastify';

export interface Template {
  id : number;
  name : string;
  createdOn : string;
}

export const useGetTemplates = (plantId : number | null, reportType : string) => {
  return useAuthQuery(`report-templates?plantId=${plantId}&reportType=${reportType}`, 0, {
    onError: () => {
      toast.error('Failed to fetch templates');
    },
    enabled: plantId !== null,
  }) as UseQueryResult<Template[]>;
};
