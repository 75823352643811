import { UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { useAuthQuery } from 'api/queries';
import {
  StandardTemplateBody,
  PlantTemplateBody,
  MaintenanceTemplateBody,
} from 'features/new-reports/models/reportsModels';

export const useGetChannelPartnerTemplate = (
  channelId : string | number,
  templateId : number,
  plantId : number,
) => {
  return useAuthQuery(
    `channel-partners/${channelId}/report-templates/${templateId}?plantId=${plantId}`,
    0,
    {
      onError: () => {
        toast.error('Failed to get template.');
      },
      enabled: templateId !== 0 && channelId !== 0 && plantId !== 0,
      select(data : StandardTemplateBody | PlantTemplateBody | MaintenanceTemplateBody) {
        if (data.type === 'Standard') return data;
        if (data.type === 'Plant')
          return {
            ...data,
            settings: {
              ...data.settings,
              imageURL: data.settings.imageURL
                ? data.settings.imageURL.split('/')[1]
                : data.settings.imageURL,
            },
          } as PlantTemplateBody;
        if (data.type === 'Maintenance') return data;

        return data;
      },
    },
  ) as UseQueryResult<StandardTemplateBody | PlantTemplateBody>;
};
