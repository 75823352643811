import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, memo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { manuButtons } from '../manu-buttons';
import { fontButton, fontMedium, fontTitle } from './styles/fontStyle';

interface ManageAccountHeaderProps {
  activeLink ?: string;
  numberOfPlant : number;
}

const ManageAccountHeader : FC<ManageAccountHeaderProps> = ({ activeLink, numberOfPlant }) => {
  const getTitle = () => {
    if (activeLink === manuButtons[0].id) return 'Personal information';
  };

  const title = getTitle();
  const managePlants = activeLink === 'manage-plants';

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography sx={{ ...fontTitle }} width='35%'>
          {title}
        </Typography>
        {managePlants && (
          <Box sx={{ width: '65%' }}>
            <Button
              sx={{ float: 'right', ...fontButton }}
              variant='outlined'
              startIcon={<AddIcon />}
            >
              Create plant
            </Button>
            <Button
              sx={{ float: 'right', ...fontButton, mr: '1rem' }}
              variant='outlined'
              startIcon={<AddIcon />}
            >
              Join plant
            </Button>
          </Box>
        )}
      </Box>
      {managePlants && (
        <Grid container>
          <Grid item xs={5}>
            <Typography
              sx={{
                py: '0.8rem',
                ml: '0.8rem',
                ...fontMedium,
                fontWeight: 700,
              }}
            >
              Plant
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={{ py: '0.8rem', ...fontMedium, fontWeight: 700 }}>Role</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(ManageAccountHeader);
