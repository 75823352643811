import React from "react";
import { Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import DropdownMenu from "features/user-panel/components/DropdownMenu/DropdownMenu";

const LoggedInProfile = () => {
  const { instance } = useMsal();

  const logout = () => {
      const logoutRequest = {
        account: instance.getActiveAccount(),
      };
      instance.logout(logoutRequest);
      localStorage.removeItem('domain_hint')
  }

  return (
    <>
      <Grid container direction="row" alignItems="center" columnSpacing={1}>
        <Grid item container alignItems="center">         
          <Grid item>
            <DropdownMenu logout={logout}/>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};
export default React.memo(LoggedInProfile);
