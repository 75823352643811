import { createTheme } from "@mui/material";
import variables from './variables.module.scss';

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#004851",
          color: "white",
        }
      }
    }
  },
  palette: {
    primary: {
      light: 'rgba(50, 113, 121, 0.08)',
      main: variables.primaryColor,
      dark: '#004851'
    },
    secondary: {
      main: variables.secondaryColor
    },
    error: {
      main: variables.errorColor
    },
    text: {
      primary: variables.textPrimary,
      secondary: variables.textSecondary
    },
    
  },
  typography: {
    fontFamily: [
      'Archivo'
    ].join(',')
  }
});

export default theme;