import * as React from 'react';
import { FC, memo } from 'react';
import Box from '@mui/material/Box';
import { StyledMenu } from './StyledMenu';
import Login from './Login';
import MenuContent from './MenuContent';
import { useGetAuthData } from 'auth/hooks';
import ManageAccount from '../ManageAccount/ManageAccount';


interface DropdownMenuProps{
    logout: ()=>void;
}

const DropdownMenu: FC<DropdownMenuProps> = ({logout}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [modalOpen, setModalOpen] = React.useState(false);

  const [link, setlLink] = React.useState<string>("manage-plants");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenManageAccount = (e: React.MouseEvent<HTMLElement>) => {
    setModalOpen(true); 
    setlLink(e.currentTarget.id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    handleClose();
  };

  const { given_name, family_name, userEmail } = useGetAuthData(); 

  return (
    <Box display="inline-block">
      <Login open={open} handleClick={handleClick} firstName={given_name} lastName={family_name} email={userEmail}/>
      <StyledMenu
        id="menu"
        MenuListProps={{
          'aria-labelledby': 'user',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuContent logout={logout} handleOpenManageAccount={handleOpenManageAccount} firstName={given_name} lastName={family_name} email={userEmail}/>
        { modalOpen && <ManageAccount open={modalOpen}  onClose={handleModalClose} link={link} logout={logout} firstName={given_name} lastName={family_name} email={userEmail} /> }
      </StyledMenu>
    </Box>
  );
}

export default memo(DropdownMenu);