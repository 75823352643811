import { useGetUserRoles } from 'api/plants-management/users/useGetUserRoles';
import { Loading } from 'components';
import { MainLayout } from 'components/Layouts';
import { UserInvitationStatus } from 'features/plants-management/models/user.model';
import { UserRoleInfo } from 'models/user-role.model';
import { PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';

const checkUserInvitationStatus = (
  roles : UserRoleInfo[],
  status : UserInvitationStatus,
) : boolean => {
  return roles.some((role : UserRoleInfo) => role.invitationStatus === status);
};

const PlantRequired = ({ children }: PropsWithChildren<void>) => {
  const { data: roles } = useGetUserRoles();

  if (!roles) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }

  if (checkUserInvitationStatus(roles, 'invited')) {
    return <Redirect to='/onboarding/invitation' />;
  }

  if (checkUserInvitationStatus(roles, 'accepted')) {
    return <>{children}</>;
  }

  if (checkUserInvitationStatus(roles, 'pending')) {
    return <Redirect to='/onboarding/Pending' />;
  }

  return <Redirect to='/onboarding/welcome' />;
};

export default PlantRequired;
