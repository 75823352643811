import { RootState } from './../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface UniversalModalState {
  blockPage: boolean;
  pageIndex: number;
  pagesAmount: number;
  storage?: object;
  payload?: any;
}

const initialState: UniversalModalState = {
  blockPage: false,
  pageIndex: 0,
  pagesAmount: 1
};

export const UniversalModalSlice = createSlice({
  name: "universalmodal",
  initialState,
  reducers : {
    reset: () => initialState,
    updatePagesAmount: (state, action: PayloadAction<number>) => {
      state.pagesAmount = action.payload;
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    unlockNextPage: (state) => {
      state.blockPage = false;
    },
    lockNextPage: (state) => {
      state.blockPage = true;
    },
    updateStorage: (state, action) => {
      state.storage = action.payload;
    }
  }
});

export const selectBlockPage = (state: RootState) => state.universalModal.blockPage;
export const selectPageIndex = (state: RootState) => state.universalModal.pageIndex;
export const selectPagesAmount = (state: RootState) => state.universalModal.pagesAmount;

export const { unlockNextPage, lockNextPage, changePage, reset, updatePagesAmount } = UniversalModalSlice.actions;
export default UniversalModalSlice.reducer;
