import { FC, memo } from 'react';
import { Box, Divider, MenuItem, Typography } from '@mui/material';
import LoginAvatar from './LoginAvatar';
import { manuButtons } from '../manu-buttons';
import moment from 'moment';

interface MenuContentProps {
  logout : () => void;
  handleOpenManageAccount : (e : React.MouseEvent<HTMLElement>) => void;
  firstName : string;
  lastName : string;
  email : string;
}

const MenuContent : FC<MenuContentProps> = ({
  logout,
  handleOpenManageAccount,
  firstName,
  lastName,
  email,
}) => {
  return (
    <>
      <Box display='flex' justifyContent='center' alignItems='center' my='22px'>
        <LoginAvatar border='1px solid black' size='2.5rem' />
        <Box>
          <Typography fontWeight='600' fontSize='1.2rem'>{`${firstName} ${lastName}`}</Typography>
          <Typography>{email}</Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 0.5 }} />
      {manuButtons.map((item) => (
        <MenuItem key={item.id} id={item.id} onClick={handleOpenManageAccount} disableRipple>
          {item.label}
        </MenuItem>
      ))}
      <Divider sx={{ my: 0.5 }} />

      <MenuItem onClick={logout} disableRipple data-testid='menu_sign_out_button'>
        Sign out
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <Box display='flex' justifyContent='space-between' alignItems='center' py='10px' px='20px'>
        <Typography fontSize='0.8rem' sx={{ color: 'rgba(118, 125, 138, 1)' }}>{`© ${moment
          .utc(new Date())
          .local()
          .format('YYYY')} Dodge Industrial, Inc.`}</Typography>
        <Typography fontSize='0.8rem' sx={{ color: 'rgba(118, 125, 138, 1)' }}>
          Terms of Service
        </Typography>
      </Box>
    </>
  );
};

export default memo(MenuContent);
