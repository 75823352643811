import { Grid, Link } from '@mui/material';
import { FC, memo } from 'react';
import { manuButtons } from '../manu-buttons';
import { fontMedium } from './styles/fontStyle';

interface ManageAccountMenuProps {
  activeLink : string;
  handleActiveLink : (e : React.MouseEvent<HTMLElement>) => void;
  logout : () => void;
}

const ManageAccountMenu : FC<ManageAccountMenuProps> = ({
  activeLink,
  handleActiveLink,
  logout,
}) => {
  return (
    <Grid container direction='column' justifyContent='space-between' height='100%'>
      <Grid item container direction='column' alignItems='flex-start'>
        {manuButtons.map((link) => (
          <Link
            key={link.id}
            id={link.id}
            component='button'
            sx={{
              display: 'block',
              my: '0.8rem',
              textDecoration: 'none',
              ...fontMedium,
              color: activeLink === link.id ? 'primary' : 'black',
            }}
            onClick={handleActiveLink}
          >
            {link.label}
          </Link>
        ))}
      </Grid>
      <Grid item>
        <Link
          id='sign-out'
          component='button'
          sx={{ display: 'block', textDecoration: 'none', ...fontMedium, color: 'black' }}
          onClick={logout}
          data-testid='manage_account_sign_out_button'
        >
          Sign Out
        </Link>
      </Grid>
    </Grid>
  );
};

export default memo(ManageAccountMenu);
