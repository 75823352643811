import { Grid, IconButton, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { FC, memo } from "react";
import { fontLarge, fontMedium } from "./styles/fontStyle";

interface UserProfileProps{
  firstName: string,
  lastName: string,
  email: string,
}

const UserProfile: FC<UserProfileProps> = ({firstName, lastName, email}) =>{
    return(
      <Grid container direction="column">
        <Grid item container>
            <Grid item xs={5}><Typography sx={{...fontLarge}} py="0.8rem">Name</Typography></Grid>
            <Grid item xs={6}><Typography sx={{...fontMedium}} py="0.8rem">{firstName}</Typography></Grid>
        </Grid>
        <Grid item container>
            <Grid item xs={5}><Typography sx={{...fontLarge}} py="0.8rem">Surname</Typography></Grid>
            <Grid item xs={6}><Typography sx={{...fontMedium}} py="0.8rem">{lastName}</Typography></Grid>
        </Grid>
        <Grid item container>
            <Grid item xs={5}><Typography sx={{...fontLarge}} py="0.8rem">Email</Typography></Grid>
            <Grid item xs={6}><Typography sx={{...fontMedium}} py="0.8rem">{email}</Typography></Grid>
        </Grid>
        <Grid item container>
            <Grid item xs={5}><Typography sx={{...fontLarge}} py="0.8rem">Password</Typography></Grid>
            <Grid item xs={6} container><Typography sx={{...fontMedium}} py="0.8rem">******************</Typography></Grid>
            <Grid item xs={1} container alignContent="center">
              <IconButton><EditIcon sx={{color:"rgba(0, 0, 0, 0.54)"}}/></IconButton>
            </Grid>
        </Grid>
      </Grid>
    );
}

export default memo(UserProfile);