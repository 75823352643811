import * as React from 'react';
import { Box, Link, Typography, Grid } from '@mui/material';
import style from './CopyrightBar.module.scss';

const CopyrightBar: React.FC = () => {
  return (
    <Box className={style.box}>
      <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
        <Grid item>
          <Typography className={style.copyright} variant='subtitle2' sx={{ mt: 2.5, ml: 4 }}>
            © Copyright 2024 Dodge Industrial, Inc.
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{ mt: 1.5, ml: 4 }}
          >
            <Grid>
              <Typography className={style.policies} sx={{ mr: 1 }}>
                <Link underline='none' color='#327179'>
                  privacy policy
                </Link>{' '}
                |{' '}
                <Link underline='none' color='#327179'>
                  cookies policy
                </Link>{' '}
                | terms and conditions:{' '}
                <Link underline='none' color='#327179'>
                  usa
                </Link>
                ,{' '}
                <Link underline='none' color='#327179'>
                  canada
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CopyrightBar);
