export const fontLarge = {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px"
}

export const fontMedium = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20.02px",
    letterSpacing: "0.15px"
}

export const fontButton = {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "26px",
    letterSpacing: "0.46px"
}

export const fontTitle = {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.15px"
}