import React from 'react'
import {Grid, CircularProgress} from '@mui/material';

const Loading = ({height}: {height?: string | number}) => {
    return (
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: height? height : '75vh', width: '100%' }}
      >
      
        <Grid item xs={3}>
            <CircularProgress size={120} />
        </Grid>   
         
      </Grid> 
    )
}

export default React.memo(Loading);