import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { Title } from 'features/new-reports/models/reportsModels';
import { FC, ReactNode } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Columns } from 'features/batteries&warranty/components/WarrantySensors/models/tableModels';

type Props = {
  cell : Title | Columns;
  asc : boolean;
  setAsc : (asc : boolean) => void;
  sort : string;
  setSort : (sort : string) => void;
  index : number;
  cellCheckbox ?: ReactNode;
  customSortName ?: string;
};
const HeaderTableCell : FC<Props> = ({
  cell,
  sort,
  asc,
  setAsc,
  setSort,
  index,
  cellCheckbox,
  customSortName,
}) => {
  const isSortable = cell.sortable;
  const handleClick = () => {
    if (isSortable) {
      if (customSortName) {
        customSortName === sort ? setAsc(!asc) : setSort(customSortName);
        return;
      }
      cell.name === sort ? setAsc(!asc) : setSort(cell.name);
    }
  };

  return (
    <TableCell width={cell.columnWidth} key={cell.name}>
      <Box display='flex' alignItems='center'>
        {cellCheckbox}
        {isSortable ? (
          <IconButton disableRipple sx={{ p: 0, color: 'inherit' }} onClick={handleClick}>
            <Typography
              fontWeight='600'
              fontSize='0.9rem'
              display='inline'
              ml={index == 0 ? 1 : undefined}
            >
              {cell.name}
            </Typography>
            {(sort === cell.name || sort === customSortName) &&
              (asc ? (
                <ArrowUpwardIcon sx={{ fontSize: '.9rem', color: '#8f8b8b', ml: 1 }} />
              ) : (
                <ArrowDownwardIcon sx={{ fontSize: '.9rem', color: '#8f8b8b', ml: 1 }} />
              ))}
          </IconButton>
        ) : (
          <Typography
            fontWeight='600'
            fontSize='0.9rem'
            display='inline'
            ml={index === 0 ? 1 : undefined}
          >
            {cell.name}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
};

export default HeaderTableCell;
