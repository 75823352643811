import React from "react";
import { Grid } from "@mui/material";
import { LeftNavbar } from "../../LeftNavbar";
import { Bar } from "../../Bar";


const LayoutContext = React.createContext(null);

type PropsTypes = {
  children: React.ReactNode;
};

// TODO: Layout
const MainLayout = ({ children }: PropsTypes) => {
  return (
    <LayoutContext.Provider value={null}>
      <Grid>
        <Grid item>
          <LeftNavbar children={children} />
        </Grid>
      </Grid>
    </LayoutContext.Provider>
  );
};

export default React.memo<PropsTypes>(MainLayout);
