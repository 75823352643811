import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CreatedLabel } from 'components/Labels/models/label.model';

export interface PlantsManagementState {
  plantLabels: CreatedLabel[];
  assetLabels: CreatedLabel[];
  refetchPlantsList : boolean;
  currentFilteredLabel?: CreatedLabel;
}

const initialState: PlantsManagementState = {
  plantLabels: [],
  assetLabels: [],
  refetchPlantsList: false,
};

export const plantsManagementSlice = createSlice({
  name: 'plantsManagement',
  initialState,
  reducers: {
    setPlantLabels: (state, action) => {
      state.plantLabels = action.payload;
    },
    setAssetLabels: (state, action) => {
      state.assetLabels = action.payload;
    },
    setRefetchPlantsList: (state, action) => {
      state.refetchPlantsList = action.payload;
    },
    setCurrentFilteredLabel: (state, action) => {
      state.currentFilteredLabel = action.payload;
    },
  },
});

export const { setPlantLabels, setRefetchPlantsList, setCurrentFilteredLabel, setAssetLabels } =
  plantsManagementSlice.actions;

export const selectPlantLabels = (state: RootState) => state.plantsManagement.plantLabels;
export const selectAssetLabels = (state: RootState) => state.plantsManagement.assetLabels;
export const selectRefetchPlantsList = (state : RootState) =>
  state.plantsManagement.refetchPlantsList;
export const selectCurrentFilteredLabel = (state : RootState) =>
  state.plantsManagement.currentFilteredLabel;

export default plantsManagementSlice.reducer;
