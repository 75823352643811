import { Box, Modal, SxProps, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25rem',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: '16px',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
};

type DodgeModalProps = {
  title ?: string | ReactNode;
  width ?: number | string;
  height ?: number | string;
  maxWidth ?: number | string;
  maxHeight ?: number | string;
  open : boolean;
  onClose ?: () => void;
  titleVariant ?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2';
  titleColor ?: string;
  padding ?: string;
  hideCloseIcon ?: boolean;
  titleFontWeight ?: string;
};

const DodgeModal: FC<DodgeModalProps> = (props) => {
  const width = props.width ?? '25rem';
  const height = props.height ?? undefined;
  const maxHeight = props.maxHeight ?? '90vh';

  const handleClose = () => {
    props.onClose?.();
  };

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box
          sx={{
            ...style,
            width,
            height,
            maxHeight,
            maxWidth: props.maxWidth,
            padding: props.padding,
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            flexDirection='row-reverse'
            alignItems='center'
          >
            {!props.hideCloseIcon && (
              <CloseIcon
                onClick={handleClose}
                sx={{ '&:hover': { cursor: 'pointer' }, position: 'relative', zIndex: '1000' }}
              />
            )}
            {props.title && (
              <Typography
                variant={props.titleVariant ?? 'h5'}
                color={props.titleColor ?? 'primary.dark'}
                fontWeight={props.titleFontWeight ?? '600'}
              >
                {props.title}
              </Typography>
            )}
          </Box>
          {props.children}
        </Box>
      </Modal>
    </Box>
  );
};

export default DodgeModal;
