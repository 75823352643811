import { useMsal } from '@azure/msal-react';
import { useAuthQuery } from 'api/queries';
import { setChannelDetails } from 'features/contracts-administration/store/channelsSlice';
import { UserRole } from 'features/plants-management/models/user.model';
import { UserRoleInfo } from 'models/user-role.model';
import moment from 'moment-timezone';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { setAutoTimezone, setTimezone } from 'store/accountSlice';

export const convertToPlantRole = (role : string) : UserRole => {
  return role
    .split('_')
    .map((x) => `${x[0].toUpperCase()}` + x.slice(1))
    .join(' ') as UserRole;
};

export const getUserRolesUrl = () => `users/roles`;

export type ChannelDetails = {
  channelId : number;
  channelName : string;
  role : string;
};

type RoleRecord = {
  plantId : number;
  plantName : string;
  role : string;
  invitationStatus : 'pending' | 'invited' | 'accepted';
  assetGroupIds : number[];
  selected : boolean;
  visible : boolean;
};
interface Response {
  autoTimezone: boolean;
  eulaAccepted: boolean;
  roles: RoleRecord[];
  timezone: string;
  channelDetails ?: ChannelDetails | null;
}

//TODO: fix later when same roles endpoint will be available on beta and prod
export const useGetUserRoles = (queryOptions?: UseQueryOptions<any, unknown, any, string>) => {
  const { instance } = useMsal();

  const logout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
    };
    instance.logout(logoutRequest);
  };

  const dispatch = useDispatch();
  const timezone = moment.tz.guess(true);

  return useAuthQuery(
    getUserRolesUrl(),
    0,
    {
      ...queryOptions,
      onSettled(data : RoleRecord[], error) {
        if (error || !data.find((f) => f.role.toLowerCase().includes('super'))) logout();
      },
      select: (response : Response) => {
        dispatch(setAutoTimezone(response.autoTimezone));
        dispatch(setTimezone(response.timezone));
        dispatch(setChannelDetails(response.channelDetails));
        return response.roles.map(
          (userRole) =>
            ({
              plantName: userRole.plantName,
              plantId: userRole.plantId,
              role: convertToPlantRole(userRole.role),
              invitationStatus: userRole.invitationStatus,
              assetGroupIds: userRole.assetGroupIds,
              selected: userRole.selected,
              visible: userRole.visible,
            } as UserRoleInfo),
        );
      },
    },
    undefined,
    {
      timezone: timezone,
    },
  ) as UseQueryResult<UserRoleInfo[]>;
};
