import { FC, memo } from 'react'
import UserProfile from './UserProfile'
import Help from './Help'
import { manuButtons } from '../manu-buttons'
import { PlantModel } from './models/plant.model'

interface ManageAccountDetailsProps {
  activeLink?: string
  firstName: string
  lastName: string
  email: string
  plantList: PlantModel[]
}

const ManageAccountDetails: FC<ManageAccountDetailsProps> = ({
  activeLink,
  firstName,
  lastName,
  email,
  plantList,
}) => {
  const getComponent = () => {
    if (activeLink === manuButtons[0].id)
      return (
        <UserProfile firstName={firstName} lastName={lastName} email={email} />
      )
  }

  const component = getComponent()

  return <>{component}</>
}

export default memo(ManageAccountDetails)
