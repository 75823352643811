import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RouterIcon from '@mui/icons-material/Router';
import FactoryIcon from '@mui/icons-material/Factory';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Link, useLocation } from 'react-router-dom';
import { Bar } from '../Bar';
import { useDispatch, useSelector } from 'react-redux';
import { setNavbarOpen, selectNavbarOpen } from 'store/accountSlice';
import style from './LeftNavbar.scss';
import { useLocalStorage } from 'storage/localStorage';
import { BatteryChargingFull, LocalFireDepartment, Sensors } from '@mui/icons-material';
import { ReactComponent as PartsPageIcon } from 'images/PartsPageIco.svg';
import { ReactComponent as ReportsPageIcon } from 'images/reports-icon.svg';
import { ReactComponent as PlatformIcon } from 'images/Icons/notes/platform.svg';
import { ReactComponent as ContractsAdm } from 'images/Icons/contracts-adm.svg';
import BarChartIcon from '@mui/icons-material/BarChart';

const redirections = [
  { icon: <DashboardIcon />, path: 'dashboard', text: 'DASHBOARD' },
  { icon: <Sensors />, path: 'sensors', text: 'SENSORS' },
  { icon: <RouterIcon />, path: 'gateways-management', text: 'GATEWAYS' },
  { icon: <FactoryIcon />, path: 'plants-management', text: 'PLANT' },
  { icon: <LocalFireDepartment />, path: 'firmware', text: 'FIRMWARE' },
  { icon: <PlatformIcon />, path: 'platform', text: 'PLATFORM' },
  { icon: <PartsPageIcon />, path: 'parts', text: 'PARTS' },
  { icon: <ReportsPageIcon />, path: 'reports', text: 'REPORTS' },
  { icon: <BatteryChargingFull />, path: 'battery-and-warranty', text: 'BATTERY & WARRANTY' },
  { icon: <ContractsAdm />, path: 'contracts-administration', text: 'CONTRACTS' },
  { icon: <BarChartIcon />, path: 'sales', text: 'SALES' },
  { icon: <ContactPageIcon />, path: 'vendor', text: 'VENDOR' },
];

const scrollBar = {
  '&::-webkit-scrollbar': { width: '7.5px' },
  '&::-webkit-scrollbar-thumb': { background: '#587478' },
  '&::-webkit-scrollbar-track-piece:start': {
    background: 'transparent',
  },
};

const drawerWidth = 170;

const key = 'isNavbarOpened';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const LeftNavbar = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector(selectNavbarOpen);
  const [value, setValue] = useLocalStorage(key, false);

  const handleDrawerOpen = () => {
    dispatch(setNavbarOpen(true));
    setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
  };

  React.useEffect(() => {
    dispatch(setNavbarOpen(value));
  }, []);

  React.useEffect(() => {
    setValue(open);
  }, [open]);

  const handleDrawerClose = () => {
    dispatch(setNavbarOpen(false));
    setTimeout(() => window.dispatchEvent(new Event('resize')), 400);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const icons = redirections.map((direction) => (
    <Link
      to={'/' + direction.path}
      sx={{ color: 'white !important' }}
      className={style.a}
      key={direction.path}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          borderRight:
            splitLocation[1] === direction.path ? '4px solid #F0B323' : '4px solid #004851',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 0 : 'auto',
              color: splitLocation[1] === direction.path ? 'white' : 'silver',
            }}
          >
            {direction.icon}
          </ListItemIcon>
          {
            // If you have a better idea how to break that text as in designs let me know pls :D ~ DG
          }
          {direction.text.toLowerCase().includes('battery') ? (
            <>
              <Typography
                sx={{
                  display: open ? 'inline' : 'none',
                  color: splitLocation[1] === direction.path ? 'white' : 'silver',
                }}
              >
                BATTERY
              </Typography>
              <Typography
                sx={{
                  display: open ? 'inline' : 'none',
                  color: splitLocation[1] === direction.path ? 'white' : 'silver',
                }}
              >
                & WARRANTY
              </Typography>
            </>
          ) : (
            <Typography
              sx={{
                display: open ? 'inline' : 'none',
                color: splitLocation[1] === direction.path ? 'white' : 'silver',
              }}
            >
              {direction.text}
            </Typography>
          )}
        </Box>
      </ListItemButton>
    </Link>
  ));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: '#004851', boxShadow: 'none' }} open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open ? (
            <Typography sx={{ pr: 3 }}>
              <Link to='/dashboard'>
                <img src='optify_logo.png' alt='OptifyLogo' style={{ width: '6rem' }} />
              </Link>
            </Typography>
          ) : (
            ''
          )}
          <Bar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        sx={{ '& .MuiDrawer-paper': { border: 0 }, position: 'relative' }}
      >
        <DrawerHeader sx={{ justifyContent: 'center' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <img src='optify_logo.png' alt='OptifyLogo' style={{ width: '6rem' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ overflowY: 'auto', ...scrollBar, flexGrow: 1 }}>{icons}</Box>
        <Divider />
        <Box
          sx={{
            py: '.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={open ? 'DDG-RGB_White.png' : 'D-DDG-RGB_White.png'}
            alt='Logo'
            className={style.img}
          />
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 2, pt: 8 }}>
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(LeftNavbar);
