import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { SelectedPlant } from 'features/new-reports/models/reportsModels';
import { ReportType } from '../components/CreateReport/GeneralInformation/SelectType';
import { SensorData } from 'api/batteries&warranty/useGetSensorList';
import { RgaStatus } from '../components/CreateReport/GeneralInformation/SelectRgaStatus';
import { SensorsUpdateBody } from 'api/batteries&warranty/useUpdateReport';
import { FailureReason } from '../components/CreateReport/DataConfiguration/SelectFailureReason';
import { TabValue, View } from '../pages/Batteries&WarrantyPage';

export interface WarrantyState {
  selectedPlant : SelectedPlant[] | null;
  tabValue : TabValue;
  view : View;
reportType : ReportType;
rgaStatus : RgaStatus;
 selectedSensors : SensorData[];
 addedSensors : SensorData[];
 editedSensors: SensorsUpdateBody[];
 date: string;
 returnDate: string;
 conclusions: string;
 recommendations: string,
 failureReason: FailureReason,
  reportName : string;
  rga : string;
  order : string;
  trackingInfo : string;
  editedReportId : string,
  isReportEdit : boolean;
}

const initialState : WarrantyState = {
  view: 'plants',
  tabValue : 'battery-status',
reportType: 'Warranty',
rgaStatus: 'All',
  selectedPlant: null,
  selectedSensors: [],
  addedSensors: [],
  editedSensors: [],
  date: new Date().toISOString(),
  returnDate: new Date().toISOString(),
  conclusions: '',
  recommendations: '',
  failureReason: '',
  reportName: '',
  rga: '',
  order: '',
  trackingInfo: '',
 isReportEdit: false,
 editedReportId : '',
};

export const warrantySlice = createSlice({
  name: 'warranty',
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setSelectedPlant: (state, action) => {
      state.selectedPlant = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setReturnDate: (state, action) => {
      state.returnDate = action.payload;
    },
    setConclusions: (state, action) => {
      state.conclusions = action.payload;
    },
    setRecommendations: (state, action) => {
      state.recommendations = action.payload;
    },
    setFailureReason: (state, action) => {
      state.failureReason = action.payload;
    },
    
    setReportName: (state, action) => {
      state.reportName = action.payload;
    },
    setRga: (state, action) => {
      state.rga = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setTrackingInfo: (state, action) => {
      state.trackingInfo = action.payload;
    },
    setRgaStatus: (state, action) => {
      state.rgaStatus = action.payload;
    },

    setSelectedSensors: (state, action) => {
      state.selectedSensors = action.payload;
    },
    setAddedSensors: (state, action) => {
      state.addedSensors = action.payload;
    },
    setEditedSensors: (state, action) => {
      state.editedSensors = action.payload;
    },

    setIsReportEdit: (state, action) => {
      state.isReportEdit = action.payload;
    },
    setEditedReportId: (state, action) => {
      state.editedReportId= action.payload;
    },

  },
});

export const {
  setView,
  setTabValue,
  setReportType,
  setConclusions,
  setRecommendations,
  setFailureReason,

  setSelectedPlant,
 setSelectedSensors,
 setAddedSensors,
 setEditedSensors,
 setReturnDate,

  setReportName,
  setDate,
  setRga,
  setOrder,
  setTrackingInfo,
  setRgaStatus,

  setIsReportEdit,
  setEditedReportId,

} = warrantySlice.actions;

export const selectView = (state : RootState) => state.warranty.view;
export const selectReportType = (state : RootState) => state.warranty.reportType;

export const selectSelectedPlant = (state : RootState) => state.warranty.selectedPlant;
export const selectSelectedSensors = (state : RootState) => state.warranty.selectedSensors;
export const selectFailureReason = (state : RootState) => state.warranty.failureReason;

export const selectTabValue = (state : RootState) => state.warranty.tabValue;
 export const selectReportName = (state : RootState) => state.warranty.reportName;
 export const selectAddedSensors = (state : RootState) => state.warranty.addedSensors;
 export const selectEditedSensors = (state : RootState) => state.warranty.editedSensors;
 export const selectRga = (state : RootState) => state.warranty.rga;
 export const selectOrder = (state : RootState) => state.warranty.order;
 export const selectTrackingInfo = (state : RootState) => state.warranty.trackingInfo;
 export const selectRgaStatus = (state : RootState) => state.warranty.rgaStatus;
 export const selectDate = (state : RootState) => state.warranty.date;
 export const selectReturnDate = (state : RootState) => state.warranty.returnDate;

export const selectConclusions = (state : RootState) => state.warranty.conclusions;
export const selectIsReportEdit = (state : RootState) => state.warranty.isReportEdit;
export const selectEditedReportId = (state : RootState) => state.warranty.editedReportId;
export const selectRecommendations = (state : RootState) => state.warranty.recommendations;

export default warrantySlice.reducer;
