import * as React from 'react'
import { useMsal } from '@azure/msal-react'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'
import type { AuthenticationResult } from '@azure/msal-browser';

type ModifiedAuthenticationResult = Omit<AuthenticationResult, "idTokenClaims"> & {
  idTokenClaims: {
      idp?: string;
  };
};

export const useGetAuthData = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [accessToken, setAccessToken] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [userInfo, setUserInfo] = React.useState<any>({ emails: [] })
  const [userEmail, setUserEmail] = React.useState<string | undefined>(
    undefined,
  )

  const accessTokenRequest = {
    scopes: [
      `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_API_SCOPE}`,
    ],
    account: accounts[0],
  }

  React.useEffect(() => {
    if (
      !!window.sessionStorage.getItem('cypress-dodgeoptify-web-test-user-data')
    ) {
      const cypressTestUserSession = JSON.parse(
        window.sessionStorage.getItem(
          'cypress-dodgeoptify-web-test-user-data',
        ) as string,
      )
      setAccessToken(cypressTestUserSession.accessToken)
      setUserInfo(cypressTestUserSession.userInfo)
      setUserEmail(cypressTestUserSession.email)
    } else if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: ModifiedAuthenticationResult) => {
          const rememberSSO = JSON.stringify(accessTokenResponse.idTokenClaims?.idp);
          localStorage.setItem('domain_hint', rememberSSO);
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken)
          setUserInfo(accounts[0].idTokenClaims)
          setUserEmail(accounts[0].username)
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken
                setAccessToken(accessToken)
                setUserInfo(accounts[0].idTokenClaims)
                setUserEmail(undefined)
              })
              .catch(function (error) {
                // Acquire token interactive failure
                setErrorMessage(error)
              })
          }
          console.log(error)
        })
    }
  }, [instance, accounts, inProgress])

  return { accessToken, errorMessage, userEmail, ...userInfo }
}
