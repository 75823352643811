import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface CommonModalState {
  isBlockedNextPage: boolean
}

const initialState: CommonModalState = {
  isBlockedNextPage: false
};

export const CommonModalSlice = createSlice({
  name: 'commonmodal',
  initialState,
  reducers: {
    unlockNextPage: (state) => {
      state.isBlockedNextPage = false
    },
    lockNextPage: (state) => {
      state.isBlockedNextPage = true
    },
  },
});


export const isBlockedNextPage = (state: RootState) => state.commonModal.isBlockedNextPage;
export const { unlockNextPage, lockNextPage } = CommonModalSlice.actions
export default CommonModalSlice.reducer;
