import { Divider, Grid } from "@mui/material";
import React, { FC, memo, useCallback } from "react";
import ManageAccountContent from "./ManageAccountContent";
import ManageAccountMenu from "./ManageAccountMenu";

interface ManageAccountContainerProps{
  link?: string,
  logout: ()=>void;
  firstName: string,
  lastName: string,
  email: string,
}

const ManageAccountContainer: FC<ManageAccountContainerProps> = ({link, logout, firstName, lastName, email}) =>{

    const [activeLink, setActiveLink] = React.useState<string>(link ? link : "manage-plants");

    const handleActiveLink = useCallback((e: React.MouseEvent<HTMLElement>) => setActiveLink(e.currentTarget.id), []);

  return(
      <Grid container>
        <Grid item xs={2} p="2rem 0" height="457px">
          <ManageAccountMenu activeLink={activeLink} handleActiveLink={handleActiveLink} logout={logout} />
        </Grid>
        <Grid item xs={1} m="2rem 0"><Divider orientation="vertical"/></Grid>
        <Grid item xs={9}  p="2rem 0 2rem 2rem">
          <ManageAccountContent activeLink={activeLink} firstName={firstName} lastName={lastName} email={email} />
        </Grid>
      </Grid>
  );
}

export default memo(ManageAccountContainer);