import { Menu, MenuProps, styled } from "@mui/material";

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius:"0px 2px 12px 12px",
      marginTop: theme.spacing(1.2),
      minWidth: 350,
      color:
        theme.palette.mode === 'light' ? 'black' : theme.palette.grey[300],
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
          letterSpacing: "0.06rem",
          fontWeight: 600,
      },
    },
  }));