import DodgeModal from "components/DodgeModal/DodgeModal";
import React from "react";
import ManageAccountContainer from "./ManageAccountContainer";

type ManageAccountProps = {
    open: boolean;
    onClose?: () => void;
    link?: string,
    logout: ()=>void;
    firstName: string,
    lastName: string,
    email: string,
  };
  
  const ManageAccount = (props: ManageAccountProps) => {
    
    return (
      <DodgeModal title="Manage Account" open={props.open} onClose={props.onClose} width="827px">
        <ManageAccountContainer link={props.link} logout={props.logout} firstName={props.firstName} lastName={props.lastName} email={props.email} />
      </DodgeModal>
    );
  };
  
  export default React.memo(ManageAccount);