import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface AuthorizationState {
  accessToken: string
}

const initialState: AuthorizationState = {
  accessToken: ''
};

export const AuthorizationSlice = createSlice({
  name: 'auhtorization',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
  },
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// export const accessToken = (state: RootState) => state.authorization.accessToken;
export const { setAccessToken } = AuthorizationSlice.actions
export default AuthorizationSlice.reducer;
