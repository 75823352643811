import { Button, Divider, TableCell, TableRow } from '@mui/material';
import MainTable from 'features/contracts-administration/components/MainTable';
import { Title } from 'features/new-reports/models/reportsModels';
import {
  selectSelectedTemplate,
  selectTemplates,
  setIsTemplateOverwrite,
  setSelectedTemplate,
} from 'features/new-reports/store/newReportSlice';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTableCell from '../../tables/HeaderTableCell';
import { Template } from 'api/reports/useGetTemplates';

const defaultRowsPageSize = 10;

export const getKey = (label : string) => {
  if (label === 'Template Name') return 'name';
  else if (label === 'Created Date') return 'createdOn';
  else return '';
};

export const TITLES : Title[] = [
  { name: 'Template Name', sortable: true, columnWidth: '45%' },
  { name: 'Created Date', sortable: true, columnWidth: '35%' },
  { name: '', sortable: false, columnWidth: '20%' },
];

const OverwriteTemplate = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultRowsPageSize);
  const [sort, setSort] = useState<string>('Created Date');
  const [asc, setAsc] = useState(false);
  const templates = useSelector(selectTemplates);
  const selectedTemplate = useSelector(selectSelectedTemplate);

  const handleOverwrite = (template: Template) => {
    dispatch(setIsTemplateOverwrite(true));
    dispatch(setSelectedTemplate(template));
  };

  const templatesData = useMemo(() => {
    return templates
      .map((x) => x)
      .sort((a, b) => {
        const id = getKey(sort);
        if (id) {
          if (id === 'createdOn') {
            return asc
              ? new Date(a[id]).getTime() - new Date(b[id]).getTime()
              : new Date(b[id]).getTime() - new Date(a[id]).getTime();
          }
          return asc
            ? (a[id] as string).localeCompare(b[id] as string)
            : (b[id] as string).localeCompare(a[id] as string);
        } else return 0;
      });
  }, [sort, asc, templates]);

  const headers = (
    <TableRow>
      {TITLES.map((item, index) => (
        <HeaderTableCell
          key={item.name}
          cell={item}
          asc={asc}
          setAsc={setAsc}
          sort={sort}
          setSort={setSort}
          index={index}
        />
      ))}
    </TableRow>
  );

  return (
    <React.Fragment>
      <Divider sx={{ mt: '1.5rem' }} />
      <MainTable
        headers={headers}
        count={templatesData.length}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      >
        {templatesData.length ? (
          templatesData.slice(page * pageSize, page * pageSize + pageSize).map((template) => (
            <TableRow key={template.id}>
              <TableCell sx={{ pl: '1.5rem', py: '.5rem' }}>{template.name}</TableCell>
              <TableCell sx={{ py: '.5rem' }}>
                {moment.utc(template.createdOn).local().format('MM/DD/YY\xa0\xa0\xa0\xa0HH:mm A')}
              </TableCell>
              <TableCell sx={{ py: '.5rem' }}>
                <Button
                  onClick={() => handleOverwrite(template)}
                  variant='outlined'
                  color='secondary'
                  size='small'
                  sx={{ color: 'black', fontWeight: '600' }}
                  disabled={selectedTemplate.id === template.id}
                >
                  Overwrite
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
              No data
            </TableCell>
          </TableRow>
        )}
      </MainTable>
    </React.Fragment>
  );
};

export default OverwriteTemplate;
